import React, { useContext } from 'react'

import { makeStyles, MenuItem, Typography } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import ResponseRatePie from 'components/Insights/Snapshot/ResponseRatePie'
import { StoreContext } from 'config/LocalStorage'
import { ResponseRateTypeEnum, SurveyTypeEnum } from 'generated/graphql'
import { colors } from 'shared/theme'
import { displayDate } from 'utils/dateUtils'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),
    fontSize: 16,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    '& >div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  responseRate: {
    marginTop: spacing(4),
    display: 'flex',
  },
  halfLeft: {
    width: '40%',
    paddingBottom: spacing(2),
  },
  halfRight: {
    width: '60%',
    paddingLeft: spacing(2),
  },
  labelAndValue: {
    display: 'flex',
    '&>h6:first-child': {
      minWidth: 130,
    },
  },
  fraction: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    marginLeft: spacing(2),
  },
  rrrLabel: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      width: 12,
      height: 12,
      marginRight: spacing(),
    },
  },
}))

export const getShowReportBy = (
  responseRateShowReportBy: { [surveyUuid: string]: ResponseRateTypeEnum },
  surveyUuid: string,
  isDefaultClientBasedResponseRateReport: boolean,
) => {
  return (
    responseRateShowReportBy[surveyUuid] ||
    (isDefaultClientBasedResponseRateReport
      ? ResponseRateTypeEnum.CLIENT
      : ResponseRateTypeEnum.PARTICIPANT)
  )
}

interface Props {
  surveyUuid: string
  surveyType: SurveyTypeEnum
  minShowableResults: number
  finished: number
  total: number
  startDate: string
  endDate?: string | null
  isDefaultClientBasedResponseRateReport: boolean
  seeMoreUrl?: string
  isOpenLinkSurvey: boolean
  isResidentSurvey?: boolean
}

const SurveyResponseRateCard: React.FC<Props> = ({
  surveyUuid,
  surveyType,
  minShowableResults,
  finished,
  total,
  startDate,
  endDate,
  isDefaultClientBasedResponseRateReport,
  seeMoreUrl,
  isOpenLinkSurvey,
  isResidentSurvey = false,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }

  const {
    store: { responseRateShowReportBy },
    updateStore,
  } = useContext(StoreContext)

  const reportByLabel = {
    [ResponseRateTypeEnum.CLIENT]:
      surveyType === SurveyTypeEnum.RESIDENT_DISCHARGE ? 'Patient' : 'Client',
    [ResponseRateTypeEnum.PARTICIPANT]: 'Participant',
  }

  const showReportBy = getShowReportBy(
    responseRateShowReportBy,
    surveyUuid,
    isDefaultClientBasedResponseRateReport,
  )
  const isResponseRateControlable = isResidentSurvey && Boolean(!seeMoreUrl) && !isOpenLinkSurvey

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <TitleWithInfoTooltip
          title="Response Rate"
          showInfoIcon={isResponseRateControlable}
          tooltip={
            <>
              <Typography>
                What is the difference between a response rate by resident vs participant?
              </Typography>
              <br />
              <Typography variant="body2">RESIDENT</Typography>
              <Typography variant="body2" color="textSecondary">
                A response rate by resident is calculated by the number of residents with at least
                one response from their party, divided by the total number of residents.
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.fraction}>
                (Resident Response Rate = Number of residents represented / Total number of
                residents)
              </Typography>
              <Typography variant="body2">PARTICIPANT</Typography>
              <Typography variant="body2" color="textSecondary">
                A response rate by participant is calculated by the number of completed surveys,
                divided by the total number of people invited.
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.fraction}>
                (Participant Response Rate = Number of completed surveys / Number of surveys sent)
              </Typography>
            </>
          }
        />
        {isResponseRateControlable && (
          <div>
            <TextDropdown
              value={showReportBy}
              onChange={e => {
                updateStore({
                  responseRateShowReportBy: {
                    ...responseRateShowReportBy,
                    [surveyUuid]: e.target.value as ResponseRateTypeEnum,
                  },
                  // We want to reset the filter types cache because the filters user in the view by participants
                  // may not be applicable to clients: for example resident_respondent_type
                  responseRateGroupByFilterType1Code: {
                    [surveyUuid]: null,
                  },
                  responseRateGroupByFilterType2Code: {
                    [surveyUuid]: null,
                  },
                })
              }}
              renderValue={reportBy => reportByLabel[reportBy as ResponseRateTypeEnum]}
              label={
                <Typography variant="body2" color="textSecondary">
                  Response Rate By:
                </Typography>
              }
            >
              <MenuItem value={ResponseRateTypeEnum.CLIENT}>
                <Typography>{reportByLabel[ResponseRateTypeEnum.CLIENT]}</Typography>
              </MenuItem>
              <MenuItem value={ResponseRateTypeEnum.PARTICIPANT}>
                <Typography>{reportByLabel[ResponseRateTypeEnum.PARTICIPANT]}</Typography>
              </MenuItem>
            </TextDropdown>
          </div>
        )}
      </div>
      <div className={classes.responseRate}>
        <div className={classes.halfLeft}>
          <ResponseRatePie
            minShowableResults={minShowableResults}
            lessThanMin={finished < minShowableResults}
            surveyUuid={surveyUuid}
            rate={total > 0 ? (finished * 100) / total : 0}
            showReportBy={showReportBy}
            includeLink
            includeLeftMargin
          />
          {isResidentSurvey && (
            <div className={classes.rrrLabel}>
              <div
                style={{
                  backgroundColor:
                    showReportBy === ResponseRateTypeEnum.CLIENT ? colors.secondary : colors.navy,
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {reportByLabel[showReportBy as ResponseRateTypeEnum]} Response Rate
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.halfRight}>
          {[
            { label: 'Responses', value: `${finished}/${total}` },
            { label: 'Start', value: displayDate(startDate) },
            { label: 'End', value: displayDate(endDate, 'Open Ended') },
          ].map(({ label, value }) => (
            <div key={label} className={classes.labelAndValue}>
              <Typography variant="subtitle1" color="textSecondary">
                {label}:
              </Typography>
              <Typography variant="subtitle1">{value}</Typography>
            </div>
          ))}
        </div>
      </div>
      {seeMoreUrl && <NavLink to={seeMoreUrl}>See more details</NavLink>}
    </div>
  )
}

export default SurveyResponseRateCard
