import React, { useState } from 'react'

import { Typography, Tooltip, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import Button from 'components/Blocks/CustomButtons/Button'
import Dialog from 'components/Blocks/Dialogs/Dialog'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import ServiceAreasTab from 'components/Settings/General/ServicesAreasTab'
import AddOptionalAndCustomButtons from 'components/Survey/Blocks/AddOptionalAndCustomButtons'
import { QuestionsAccordion, QuestionsByFocusAccordion } from 'components/Survey/Blocks/Questions'
import WelcomeMessagePanel from 'components/Survey/Blocks/WelcomeMessagePanel'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import MarketingAddons from 'components/Survey/Wizard/Steps/Questions/MarketingAddons'
import MissingTranslationsWarning from 'components/Survey/Wizard/Steps/Questions/MissingTranslationsWarning'
import NpsQuestions, {
  NPS_QUESTION_CODES,
} from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import PersonalInfoQuestions from 'components/Survey/Wizard/Steps/Questions/PersonalInfoQuestions'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import {
  QCategory,
  QResidentFocus,
  SurveyTypeEnum,
  SurveysQuestionsQuery,
  SurveysSurveyQuery,
  BenchmarkCodeType,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { getSurveyTypeLabel } from 'utils'
import { MARKETING_ADDON_QUESTION_FOCUSES, RESIDENT_QUESTION_FOCUSES } from 'utils/constants'

export type QuestionsByCategory = {
  [k in QCategory]?: SurveysQuestionsQuery['questions']
}
const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    display: 'flex',
    textTransform: 'uppercase',
    paddingBottom: spacing(),
  },
  tooltipIcon: {
    color: palette.common.navy25,
    display: 'inline-block',
    marginLeft: spacing(),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(),
  },
  accordionRow: {
    paddingLeft: 22,
    paddingTop: 14,
    paddingBottom: 14,
    borderTop: `1px solid ${palette.common.navy25}`,
    '& >p': {
      display: 'flex',
      alignItems: 'center',
      color: palette.common.navy65,
    },
  },
  answerScale: {
    marginTop: spacing(4),
    fontStyle: 'italic',
    fontWeight: 100,
  },
  serviceAreasButton: {
    paddingTop: spacing(2),
  },
  dialogContainer: {
    margin: 0,
  },
  categoryPanel: {
    borderTop: `1px solid ${palette.common.navy25}`,
  },
}))

type SummaryProps = {
  category?: QCategory
  hasOptionalStatements: boolean
  numQuestions: number
  surveyType: SurveyTypeEnum
  productType: SurveyProductTypeEnum
}
const QuestionCategorySummary: React.FC<SummaryProps> = ({
  category,
  hasOptionalStatements,
  numQuestions,
  surveyType,
  productType,
}) => {
  const classes = useStyles()

  let title
  let description
  let answerScale
  if (!category) {
    title = productType === SurveyProductTypeEnum.RESIDENT ? 'Customer Survey' : 'Employee Survey'
    description = `The survey consists of a core survey, with optional questions
    you can choose to include. You can also add custom questions.`
  } else if (category === QCategory.STATEMENTS) {
    title = 'Statements'
    description = `These core statements help measure overall ${
      productType === SurveyProductTypeEnum.RESIDENT ? 'customer' : 'employee'
    } satisfaction across key areas.`
    if (hasOptionalStatements) {
      description +=
        ' Some of the questions are optional, so remove the ones you don’t want to include.'
    }
    answerScale = `Linear scale`
  } else if (category === QCategory.DEMOGRAPHICS) {
    title = 'Demographics'
    let subject = 'client and family members'
    if (productType === SurveyProductTypeEnum.EMPLOYEE) {
      subject = 'employees'
    }
    if (surveyType === SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY) {
      subject = 'participants'
    }
    description = `Collect demographic information about your ${subject} to ensure
        the experience is consistent for all - regardless of their gender, age, location, and more.`
    answerScale = 'Multiple-choice'
  } else if (category === QCategory.OPEN_ENDED) {
    title = 'Open Ended'
    let subject = 'Client and their family members'
    if (productType === SurveyProductTypeEnum.EMPLOYEE) {
      subject = 'Employees'
    }
    if (surveyType === SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY) {
      subject = 'Participants'
    }
    description = `${subject} can share additional feedback on their
        experience by leaving comments.`
    answerScale = 'Free form text'
  }
  return (
    <GridContainer>
      <ItemGrid sm={4}>
        <Typography variant="h6">{title}</Typography>
        <Typography color="textSecondary">{numQuestions} questions</Typography>
      </ItemGrid>
      <ItemGrid sm={8}>
        <Typography color="textSecondary">{description}</Typography>
        {answerScale && (
          <Typography className={classes.answerScale} color="textSecondary">
            Answer Scale: {answerScale}
          </Typography>
        )}
      </ItemGrid>
    </GridContainer>
  )
}

// This list determines the questions that custom questions can be ordered after.
// Overall satisfaction questions must come first, so we filter them from the options.
// The Testimonials question and the Online review question should always be last questions.
// Also see the "_offset_custom_resident_index" function in the backend
export const getQuestionsListForAddingCustomQuestion = (
  allQuestions: NonNullable<SurveysQuestionsQuery['questions']>,
  surveyType: SurveyTypeEnum,
) =>
  orderBy(
    allQuestions.filter(
      q =>
        // TODO: Make sure in the backend that we're placing the question after the child with
        // the highest index.
        !q.dependsOn &&
        (!q.benchmarkCode ||
          (q.benchmarkCode &&
            ![
              BenchmarkCodeType.ONLINE_REVIEW,
              BenchmarkCodeType.TESTIMONIALS_APPROVAL,
              ...NPS_QUESTION_CODES,
            ].includes(q.benchmarkCode))) &&
        (surveyType !== SurveyTypeEnum.RESIDENT_ENGAGEMENT ||
          q.residentFocus !== QResidentFocus.OVERALL_SATISFACTION),
    ),
    'index',
  )

type Props = {
  survey: SurveysSurveyQuery['survey']
  questions: NonNullable<SurveysQuestionsQuery['questions']>
  goBack(): void
  goNext(uuid?: string): void
  handleDeleteQuestion(questionUuid?: string): void
  refetchQuestions(): void
}
const ConfigurableQuestions: React.FC<Props> = ({
  survey,
  questions,
  goBack,
  goNext,
  handleDeleteQuestion,
  refetchQuestions,
}) => {
  const classes = useStyles()
  const [isOpenTranslationsDialog, setIsOpenTranslationsDialog] = useState(false)
  const [showServicesModal, setShowServicesModal] = useState(false)

  const questionsByCategory = groupBy(questions, 'category') as QuestionsByCategory
  // Count the questions by summing the groups, ignoring "Other" category for now.
  const questionsCount = Object.keys(questionsByCategory)
    .filter((key): key is keyof QuestionsByCategory => key !== QCategory.OTHER)
    .reduce((sum, key) => sum + questionsByCategory[key]!.length, 0)

  const categories = [
    QCategory.PERSONAL_INFO,
    QCategory.STATEMENTS,
    QCategory.OPEN_ENDED,
    QCategory.DEMOGRAPHICS,
  ]
  const existingQuestions = getQuestionsListForAddingCustomQuestion(questions, survey.type)
  const surveyTypeLabel = getSurveyTypeLabel(survey.type)
  const hasOptionalStatements = Boolean(
    questionsByCategory[QCategory.STATEMENTS]?.some(q => !q.required),
  )
  return (
    <>
      <SurveyControls
        survey={survey}
        showPreview
        showTranslations
        isOpenTranslationsDialog={isOpenTranslationsDialog}
        setIsOpenTranslationsDialog={setIsOpenTranslationsDialog}
      />
      <div className={classes.title}>
        <Typography>{surveyTypeLabel} Survey</Typography>
        <Tooltip
          title={`Our ${surveyTypeLabel} Survey is based on the latest research into measuring
            satisfaction and is set up to help you be able to access the largest number of
            benchmarks and be able to easily compare for resident and employee feedback.
            The survey consists of a core survey, with some optional questions you can choose
            to include or not. You can also add custom questions to dig into any additional
            areas you'd like to better understand.`}
        >
          <InfoIcon className={classes.tooltipIcon} />
        </Tooltip>
      </div>
      <WelcomeMessagePanel survey={survey} />
      <FormPanel gutterTop expandIcon={EXPAND_ICON.ARROW} extraClassName={classes.categoryPanel}>
        <QuestionCategorySummary
          hasOptionalStatements={hasOptionalStatements}
          numQuestions={questionsCount}
          surveyType={survey.type}
          productType={survey.productType}
        />
      </FormPanel>
      {categories.map(category => {
        const questionsFocusMap = groupBy(questionsByCategory[category], 'residentFocus')
        // Formatting for usage by the common QuestionBlock components
        const questionsByFocus = Object.keys(questionsFocusMap)
          .filter(
            (key: string) => !MARKETING_ADDON_QUESTION_FOCUSES.includes(key as QResidentFocus),
          )
          .map((key: string) => ({
            focus: key,
            questions: questionsFocusMap[key].filter(
              q => !q.benchmarkCode || !NPS_QUESTION_CODES.includes(q.benchmarkCode),
            ),
            // Add a footer modal for editing service areas
            footer:
              key === QResidentFocus.SERVICE_AREAS ? (
                <Button
                  className={classes.serviceAreasButton}
                  onClick={() => setShowServicesModal(true)}
                  color="secondaryNoBackground"
                >
                  Edit Service Areas
                </Button>
              ) : null,
          }))
          .filter(groupedQuestions => groupedQuestions.questions.length > 0)
        const numFocusQuestions = questionsByFocus.reduce(
          (sum, { questions: focusQuestions }) => sum + focusQuestions.length,
          0,
        )
        const questionGroup = { questionsByFocus, category }
        if (category === QCategory.PERSONAL_INFO) {
          const personalInfoQuestions = questionsByCategory[category]
          if (personalInfoQuestions?.length) {
            return (
              <PersonalInfoQuestions
                key={category}
                questions={personalInfoQuestions}
                surveyUuid={survey.uuid}
              />
            )
          }
          return <div key={category} />
        }
        return (
          <div key={category}>
            <FormPanel
              square
              removeDetailsPadding
              expandIcon={EXPAND_ICON.ARROW}
              key={category}
              extraClassName={classes.categoryPanel}
              summary={
                <QuestionCategorySummary
                  hasOptionalStatements={hasOptionalStatements}
                  category={category}
                  numQuestions={numFocusQuestions}
                  surveyType={survey.type}
                  productType={survey.productType}
                />
              }
            >
              <>
                {questionsByFocus.length > 1 ? (
                  <QuestionsByFocusAccordion
                    existingQuestions={existingQuestions}
                    surveyUuid={survey.uuid}
                    handleOpenDeleteQuestion={handleDeleteQuestion}
                    category={category}
                    questionsByFocus={questionsByFocus}
                    focusTitleMap={RESIDENT_QUESTION_FOCUSES}
                  />
                ) : (
                  <QuestionsAccordion
                    existingQuestions={existingQuestions}
                    surveyUuid={survey.uuid}
                    handleOpenDeleteQuestion={handleDeleteQuestion}
                    gq={questionGroup}
                  />
                )}
                <div className={classes.accordionRow}>
                  <AddOptionalAndCustomButtons
                    survey={survey}
                    questionCategory={category}
                    selectedQuestions={Object.values(questionsByFocus).flatMap(
                      group => group.questions,
                    )}
                  />
                </div>
              </>
            </FormPanel>
            {category === QCategory.OPEN_ENDED && (
              <NpsQuestions survey={survey} surveyQuestions={questions} />
            )}
          </div>
        )
      })}
      <MarketingAddons
        survey={survey}
        surveyQuestions={questions.filter(question => !question.isCustom)}
      />
      <div className={classes.footer}>
        {survey.hasMissingTranslations ? (
          <MissingTranslationsWarning onOpen={() => setIsOpenTranslationsDialog(true)} />
        ) : (
          <div />
        )}
        <NavigationButtons
          goBack={goBack}
          goNext={goNext}
          goNextLabel={survey.uuid ? 'Next' : 'Save and Continue'}
        />
      </div>
      {showServicesModal && (
        <Dialog
          onClose={() => setShowServicesModal(false)}
          classes={{ paper: classes.dialogContainer }}
        >
          <ServiceAreasTab
            onSave={async () => {
              await refetchQuestions()
              setShowServicesModal(false)
            }}
          />
        </Dialog>
      )}
    </>
  )
}

export default ConfigurableQuestions
