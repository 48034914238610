import React, { useContext, useState } from 'react'

import { makeStyles, Menu, MenuItem, IconButton, Paper, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import StarIconFilled from '@material-ui/icons/Star'
import StarIconUnfilled from '@material-ui/icons/StarBorder'
import { NavLink } from 'react-router-dom'

import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import StatusTag from 'components/Blocks/Tags/StatusTag'
import Tag from 'components/Blocks/Tags/Tag'
import CompletionRateBox from 'components/Insights/Snapshot/CompletionRateBox'
import ResponseRatePie from 'components/Insights/Snapshot/ResponseRatePie'
import DeleteSurveyDialog from 'components/Survey/Blocks/DeleteSurveyDialog'
import { getShowReportBy } from 'components/Survey/Wizard/SurveyResponseRateCard'
import { StoreContext } from 'config/LocalStorage'
import {
  SurveyStatusEnum,
  useUserToggleFavoriteSurveyMutation,
  SurveyDistributionTypeEnum,
  useSurveysSurveyResponseRateQuery,
  ResponseRateTypeEnum,
  SurveysSurveysQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { getSurveyTypeLabel } from 'utils'
import { surveyPage, SURVEY_URLS } from 'utils/constants'
import { displayDate } from 'utils/dateUtils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  survey: {
    width: '100%',
    height: 220,
    marginBottom: spacing(4),
    padding: spacing(2),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  linkToSurvey: {
    color: palette.common.navy,
  },
  surveyTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      '& >svg': {
        color: palette.common.navy65,
      },
      '& >button': {
        margin: -12, // Use negative margin to maintain padding for the hover.
        marginTop: -10, // Hacking an extra 2px so that the button aligns with title text
        marginLeft: spacing(),
      },
    },
    '& >a>h6': {
      lineHeight: 1.2,
      '&:hover': {
        color: palette.common.secondary,
      },
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      marginRight: spacing(),
      color: palette.common.navy65,
    },
  },
  footer: {
    maxHeight: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    '&>div': {
      bottom: spacing(2),
    },
  },
  surveyDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  attributes: {
    right: spacing(2),
    '&>h6': {
      display: 'flex',
      justifyContent: 'flex-start',
      fontSize: '1.4rem',
    },
  },
  label: {
    color: palette.text.secondary,
    marginRight: 20,
    width: 70,
  },
}))

export type SurveyNode = NonNullable<
  NonNullable<NonNullable<NonNullable<SurveysSurveysQuery['surveys']>['edges']>[0]>['node']
>

interface Props {
  survey: SurveyNode
  isFavorite: boolean
}

const SurveyCard: React.FC<Props> = ({ survey, isFavorite }) => {
  const classes = useStyles()

  let wizardTab = SURVEY_URLS.DESIGN
  if (!survey.editable) {
    if (survey.distributionType === SurveyDistributionTypeEnum.OPEN) {
      wizardTab = SURVEY_URLS.DISTRIBUTION
    } else {
      wizardTab = SURVEY_URLS.SUMMARY
    }
  }
  const surveyUrl = surveyPage(survey.uuid, wizardTab)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const isOpenLinkSurvey = survey.distributionType === SurveyDistributionTypeEnum.OPEN
  const [toggleFavorite] = useUserToggleFavoriteSurveyMutation()

  return (
    <ItemGrid sm={6} lg={4}>
      {openDeleteDialog && (
        <DeleteSurveyDialog
          onDelete={() => {
            setOpenDeleteDialog(false)
            setMenuAnchorEl(null)
          }}
          onClose={() => {
            setOpenDeleteDialog(false)
          }}
          surveyUuid={survey.uuid}
        />
      )}
      <Paper className={classes.survey}>
        <div>
          <div className={classes.surveyTitle}>
            <NavLink className={classes.linkToSurvey} to={surveyUrl}>
              <Typography variant="h6">{survey.name}</Typography>
            </NavLink>
            <div>
              {isFavorite && <StarIconFilled />}
              <IconButton onClick={e => setMenuAnchorEl(e.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
          <StatusTag status={survey.status} isOpenLinkSurvey={isOpenLinkSurvey} />
          <Tag>{survey.productType === 'RESIDENT' ? 'CUSTOMER' : 'EMPLOYEE'}</Tag>
          <Tag>{getSurveyTypeLabel(survey.type, false)}</Tag>
        </div>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          keepMounted
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              toggleFavorite({ variables: { surveyUuid: survey.uuid } })
              setMenuAnchorEl(null)
            }}
            className={classes.menuItem}
          >
            <StarIconUnfilled /> {isFavorite ? 'Remove' : ''} Favorite
          </MenuItem>
          {survey.status === SurveyStatusEnum.DRAFT && (
            <MenuItem onClick={() => setOpenDeleteDialog(true)}>Delete Draft</MenuItem>
          )}
        </Menu>
        <SurveyResponseRateContainer survey={survey} isOpenLinkSurvey={isOpenLinkSurvey} />
      </Paper>
    </ItemGrid>
  )
}

interface SurveyResponseRateContainerProps {
  survey: SurveyNode
  isOpenLinkSurvey: boolean
}
const SurveyResponseRateContainer: React.FC<SurveyResponseRateContainerProps> = props => {
  const classes = useStyles()

  const {
    store: { responseRateShowReportBy },
  } = useContext(StoreContext)

  const showReportBy = getShowReportBy(
    responseRateShowReportBy,
    props.survey.uuid,
    props.survey.isDefaultClientBasedResponseRateReport,
  )

  const colorMap = {
    DRAFT: colors.iceGrey,
    SCHEDULED: colors.warning,
    LIVE: colors.success,
    CLOSED: colors.navy,
  }

  const result = useSurveysSurveyResponseRateQuery({
    variables: {
      surveyUuid: props.survey.uuid,
      byClient: showReportBy === ResponseRateTypeEnum.CLIENT,
    },
  })

  return (
    <ResponseHandler {...result}>
      {responseRateResult => {
        const responseRateSurvey = responseRateResult.survey
        // Use insightsSurvey for response rate data that may be `byClient` and top level survey for `byParticipant`.
        const { responseRate, completionRate } = responseRateSurvey.insightsSurvey

        let responses = 'N/A'
        if (!props.survey.editable) {
          if (props.isOpenLinkSurvey) {
            responses = `${completionRate.started} Total Responses`
          } else {
            responses = `${responseRate.finished}/${responseRate.total} Participants`
          }
        }
        return (
          <div className={classes.footer}>
            <div className={classes.attributes}>
              {[
                {
                  label: 'Responses',
                  value: (
                    <NavLink to={surveyPage(props.survey.uuid, SURVEY_URLS.MONITOR)}>
                      {responses}
                    </NavLink>
                  ),
                },
                {
                  label: 'Start',
                  value: displayDate(props.survey.startDate),
                },
                {
                  label: 'End',
                  value: displayDate(props.survey.endDate, 'Open Ended'),
                },
              ].map(({ label, value }) => (
                <Typography variant="subtitle1" key={label}>
                  <span className={classes.label}>{label}:</span>
                  {value}
                </Typography>
              ))}
            </div>
            {props.isOpenLinkSurvey ? (
              <CompletionRateBox
                surveyStatus={props.survey.status}
                numCompletedResponses={completionRate.started}
              />
            ) : (
              <ResponseRatePie
                minShowableResults={props.survey.minShowableResults}
                lessThanMin={responseRateSurvey.hasLessThanMinShowableResults}
                surveyUuid={props.survey.uuid}
                rate={responseRate.rate}
                chartColor={colorMap[props.survey.status]}
                showReportBy={showReportBy}
                includeLink
                thin
              />
            )}
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default SurveyCard
