import React from 'react'

import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import cn from 'classnames'

import SingleBarBreakdownChart from 'components/Blocks/Charts/SingleBarBreakdownChart'
import Button from 'components/Blocks/CustomButtons/Button'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import ResponseRatePie from 'components/Insights/Snapshot/ResponseRatePie'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { gaEvent } from 'config/ga'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  useInsightsLonelinessSummaryQuery,
  useInsightsDownloadLazyQuery,
  UserDownloadsEnum,
} from 'generated/graphql'
import { getFirstViridisColors } from 'utils'
import { useDownloadMachine } from 'utils/customHooks'

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(2),
    '&>* > h4': {
      paddingTop: theme.spacing(4),
    },
  },
  downloadButton: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& >button': {
      marginLeft: 0,
      paddingLeft: 0,
      '& >span>svg': {
        marginRight: theme.spacing(1),
        color: theme.palette.common.navy65,
      },
    },
  },
  chartContainer: {
    background: theme.palette.common.iceGrey50,
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& >div': {
      width: '50%',
    },
  },
  subDescription: {
    fontSize: '1.3rem',
  },
  pieChartContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  lonelinessBlurb: {
    width: '60%',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    '& >p': {
      paddingTop: theme.spacing(1),
    },
  },
  legends: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
    },
  },
  legendBox: {
    width: 7,
    height: 7,
    marginRight: 5,
  },
  legendText: {
    fontSize: '1.2rem',
  },
  title: {
    textTransform: 'uppercase',
  },
  tooltipBody: {
    minWidth: 900,
    borderBottom: 0,
  },
  tooltipInfoRowHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tooltipInfoRow: {
    display: 'flex',
    '& >p:nth-child(1)': {
      width: '45%',
    },
    '& >p:nth-child(2)': {
      width: '15%',
    },
    '& >p:nth-child(3)': {
      width: '10%',
    },
    '& >p:nth-child(4)': {
      width: '30%',
    },
  },
}))

export const LonelinessTooltip = () => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={cn(classes.fullRow, classes.tooltipBody)}>
      <Typography variant="h6">Loneliness Scale</Typography>
      {[
        ['Questions:', 'Response:', 'Score:', 'Loneliness:'],
        [
          '1. How often do you feel that you lack companionship?',
          'Hardly Ever',
          1,
          'Score of 3: Not Lonely',
        ],
        [
          '2. How often do you feel left out?',
          'Some of the time',
          2,
          'Score of 4 to 5: Somewhat Lonely',
        ],
        ['3. How often do you feel isolated from others?', 'Often', 3, 'Score of 6 to 9: Lonely'],
      ].map((texts, idx) => {
        return (
          <div key={`${idx}text`} className={classes.tooltipInfoRow}>
            {texts.map(text => (
              <Typography
                color={idx === 0 ? 'textPrimary' : 'textSecondary'}
                className={cn({ [classes.tooltipInfoRowHeader]: idx === 0 })}
                key={text}
              >
                {text}
              </Typography>
            ))}
          </div>
        )
      })}
    </div>
  )
}

type Props = {
  surveyUuid: string
  filters: string[]
  hasTimeTrending: boolean
  minShowableResults: number
}
const LonelinessCard: React.FC<Props> = ({
  surveyUuid,
  filters,
  hasTimeTrending,
  minShowableResults,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const result = useInsightsLonelinessSummaryQuery({
    variables: { surveyUuid, filters },
  })
  const [downloadLonelinessReport, { data, error }] = useInsightsDownloadLazyQuery({
    variables: { surveyUuid, filters, downloadType: UserDownloadsEnum.LONELINESS_REPORT },
    fetchPolicy: 'network-only',
  })
  const { state, send } = useDownloadMachine(
    downloadLonelinessReport,
    Boolean(data?.success),
    error,
  )
  return (
    <ResponseHandler {...result}>
      {({ insightsLonelinessSummary: { numLonely, numSomewhatLonely, numNotLonely } }) => {
        const total = numLonely + numSomewhatLonely + numNotLonely
        if (total === 0) return <div />
        const getRate = (num: number) => Math.round((num / total) * 100)
        const rates: [number, number, number] = [
          getRate(numLonely),
          getRate(numSomewhatLonely),
          getRate(numNotLonely),
        ]
        const colors = getFirstViridisColors()
        return (
          <div className={cn(classes.fullRow, classes.container)} id="loneliness-snap">
            <div>
              <SnapshotChartHeader
                title="Understanding Loneliness"
                description="Seniors are at a high risk for suffering from loneliness. Social isolation and loneliness impact quality of life and can impact health as well. Understanding - and addressing - loneliness can help you better support the seniors you serve."
                hasTimeTrending={hasTimeTrending}
                timeTrendingChartKey={TimeTrendingChartKey.RESIDENT_LONELINESS}
              />
              <Typography color="textSecondary">
                Download your loneliness report to see which clients self-identified as lonely and
                shared their contact info.
              </Typography>
              <div className={classes.downloadButton}>
                <Button
                  disabled={state.matches('loading')}
                  onClick={() => send('FETCH_DOWNLOAD')}
                  color="secondaryNoBackground"
                >
                  <CloudDownloadIcon />
                  Download Full Report
                </Button>
              </div>
              <div className={classes.chartContainer}>
                <div>
                  <Typography className={classes.title}>Loneliness Score</Typography>
                  <div className={classes.pieChartContainer}>
                    <ResponseRatePie
                      chartColor={colors[0]}
                      rate={rates[0]}
                      minShowableResults={minShowableResults}
                    />
                    <div className={classes.lonelinessBlurb}>
                      <Typography variant="h4">
                        {String(numLonely)}/{String(total)}
                      </Typography>
                      <Typography color="textSecondary">
                        {`${rates[0]}% of your residents self-reported that they are experiencing loneliness.`}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        className={cn(classes.subDescription, classes.italics)}
                      >
                        Compared to the national benchmark of 26.6%.
                      </Typography>
                    </div>
                  </div>
                </div>
                <div>
                  <Typography className={classes.title}>
                    Score Breakdown
                    <Tooltip
                      placement="top"
                      classes={{ tooltip: classes.tooltipBody }}
                      title={<LonelinessTooltip />}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Typography>

                  <SingleBarBreakdownChart scores={rates} minShowableResults={minShowableResults} />
                  <div className={classes.legends}>
                    <div>
                      <div className={classes.legendBox} style={{ backgroundColor: colors[0] }} />
                      <Typography className={classes.legendText}>Lonely</Typography>
                    </div>
                    <div>
                      <div className={classes.legendBox} style={{ backgroundColor: colors[1] }} />
                      <Typography className={classes.legendText}>Somewhat Lonely</Typography>
                    </div>
                    <div>
                      <div className={classes.legendBox} style={{ backgroundColor: colors[2] }} />
                      <Typography className={classes.legendText}>Not Lonely</Typography>
                    </div>
                  </div>
                  <Typography color="textSecondary" className={classes.italics}>
                    Points are added up giving each participant a score of 0 to 9.
                    <br />
                    <a
                      target="_blank"
                      href="https://activatedinsights.force.com/help/s/article/Loneliness-Section-FAQs"
                      rel="noopener noreferrer"
                      onClick={() => {
                        gaEvent({
                          action: 'learnMoreLoneliness',
                          category: 'Insights',
                        })
                      }}
                    >
                      Learn More {'>'}
                    </a>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default LonelinessCard
