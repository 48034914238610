import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutlined'
import { useHistory } from 'react-router-dom'

import { AnalyticsSurveyNode } from 'components/Analytics/AnalyticsContainer'
import SingleBarBreakdownChart from 'components/Blocks/Charts/SingleBarBreakdownChart'
import Button from 'components/Blocks/CustomButtons/Button'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import StandardTooltip from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import { gaEvent } from 'config/ga'
import {
  InsightsModulesEnum,
  InsightsOverlayDrivingStatementsQuery,
  OrganizationFragment,
  RankByEnum,
  SurveyProductTypeEnum,
  useInsightsOverlayDrivingStatementsQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatTooltipScore } from 'utils'
import { MIN_SHOWABLE_RESULTS_CODE, PRODUCT_TYPE_TO_OVERLAY_COLORS } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
  cardRowContainer: {
    display: 'flex',
    '& >div': {
      width: '50%',
      '&:nth-child(1)': {
        borderRight: `1px solid ${palette.common.navy25}`,
      },
    },
    border: `1px solid ${palette.common.navy25}`,
  },
  header: {
    padding: spacing(),
    display: 'flex',
    background: palette.common.iceGrey65,
    '& >p': {
      paddingLeft: spacing(),
      textTransform: 'uppercase',
    },
  },
  body: {
    padding: spacing(2),
    '& >div': {
      margin: spacing(4),
      '& >p': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  seeMoreButton: {
    fontSize: '1.4rem',
    '& >svg': {
      height: 4,
    },
  },
  statementChart: {
    paddingBottom: spacing(2),
  },
}))

type CardProps = {
  statements: InsightsOverlayDrivingStatementsQuery['positiveEmployeeDrivingStatements']
  survey: AnalyticsSurveyNode
  organization: OrganizationFragment
}
const DrivingStatementsCard: React.FC<CardProps> = ({ statements, survey, organization }) => {
  const history = useHistory()
  const classes = useStyles()
  const showSeeMore = {
    [SurveyProductTypeEnum.RESIDENT]: organization.residentSolution?.insightsComparisons,
    [SurveyProductTypeEnum.EMPLOYEE]: organization.solution?.insightsComparisons,
  }[survey.productType]
  return (
    <div>
      <div className={classes.header}>
        <AddIcon style={{ color: colors.success }} />
        <Typography color="textSecondary">
          <span style={{ color: colors.success }}>More Positive</span>
          &nbsp;{survey.productType === 'RESIDENT' ? 'CLIENT' : 'EMPLOYEE'} Experience
        </Typography>
      </div>
      <div className={classes.body}>
        <Typography color="textSecondary">
          These statements correlate to a more positive {survey.productType.toLowerCase()}{' '}
          experience.
        </Typography>
        <div>
          {statements
            .filter(stmt => stmt.positive !== MIN_SHOWABLE_RESULTS_CODE)
            .slice(0, 2)
            .map(stmt => {
              return (
                <div
                  className={classes.statementChart}
                  key={stmt.code + survey.uuid}
                  id={`overlay-driving-statement-${stmt.code}`}
                >
                  <Typography>{stmt.label}</Typography>
                  <SingleBarBreakdownChart
                    minShowableResults={survey.minShowableResults}
                    scores={[stmt.positive]}
                    lessThanMin={false}
                    useGrid
                    barColors={[PRODUCT_TYPE_TO_OVERLAY_COLORS[survey.productType]]}
                    barSize={20}
                    labelPosition="insideRight"
                    tooltip={
                      <StandardTooltip
                        title={stmt.label}
                        rows={[
                          {
                            value: formatTooltipScore(stmt.positive, survey.minShowableResults),
                            label: 'Positive:',
                            color: colors.success,
                          },
                          {
                            value: formatTooltipScore(stmt.inconsistent, survey.minShowableResults),
                            label: 'Inconsistent:',
                            color: colors.warning,
                          },
                          {
                            value: formatTooltipScore(stmt.negative, survey.minShowableResults),
                            label: 'Negative:',
                            color: colors.danger,
                          },
                          {
                            value: String(stmt.overallPositiveCorrelation?.toFixed(2)),
                            label: 'Correlation coefficient: ',
                          },
                        ]}
                      />
                    }
                  />
                  {showSeeMore && (
                    <Typography color="textSecondary" variant="body2">
                      See how scores compare&nbsp;
                      <Button
                        id={`overlay-see-more-${stmt.code}`}
                        noMargins
                        color="secondaryNoBackground"
                        className={classes.seeMoreButton}
                        onClick={() => {
                          gaEvent({
                            action: 'overlayDrivingStatementSeeMore',
                            category: 'Analytics',
                          })
                          history.push(
                            `${getInsightsPage(
                              survey.uuid,
                              InsightsModulesEnum.COMPARISONS,
                              survey.productType,
                            )}?statement=${stmt.code}`,
                          )
                        }}
                      >
                        See More &gt;
                      </Button>
                    </Typography>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
type Props = {
  employeeSurvey: AnalyticsSurveyNode
  residentSurvey: AnalyticsSurveyNode
  filters: string[]
  organization: OrganizationFragment
}
const OverlayDrivingStatements: React.FC<Props> = ({
  employeeSurvey,
  residentSurvey,
  filters,
  organization,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const result = useInsightsOverlayDrivingStatementsQuery({
    variables: {
      employeeSurveyUuid: employeeSurvey.uuid,
      residentSurveyUuid: residentSurvey.uuid,
      filters,
      rankByPositive: RankByEnum.OVERALL_POSITIVE_CORRELATION,
    },
  })
  return (
    <ResponseHandler {...result}>
      {data => {
        return (
          <div className={classes.fullRow} id="overlay-driving-statements">
            <SnapshotChartHeader
              title="Top Drivers for a Better or Worse Experience"
              description={
                <span>
                  The top drivers are statements most correlated with a high engagement score.
                  Addressing these areas can help improve overall scores. Hover to see how strong
                  the correlation is&nbsp;
                  <ExternalNavLink
                    to="https://activatedinsights.force.com/help/s/article/Correlation-Coefficient"
                    label="Learn More >"
                  />
                </span>
              }
              snapId="overlay-driving-statements"
              screenshotStrategy="html2canvas"
            />
            <div className={classes.cardRowContainer}>
              <DrivingStatementsCard
                statements={data.positiveEmployeeDrivingStatements}
                survey={employeeSurvey}
                organization={organization}
              />
              <DrivingStatementsCard
                statements={data.positiveResidentDrivingStatements}
                survey={residentSurvey}
                organization={organization}
              />
            </div>
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default OverlayDrivingStatements
